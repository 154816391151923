<template>
  <surround-box :width="380" :title="currentFarm.mapName + '施用点'">
    <div class="detect-pane">
      <!-- <p class="title">{{ currentFarm.mapName }}施用点</p> -->
      <div>
        <Select @on-change="changeSel" v-model="qualityInfo">
          <Option
            v-for="(item, index) in qualitySel"
            :key="index"
            :value="item.name"
          >
            {{ item.name }}
          </Option>
        </Select>
      </div>
      <div v-for="(item, index) in qualityData" :key="index">
        <p class="claim">
          {{ item.description }}
        </p>
        <!-- <Table :columns="tableOne.columns" :data="tableOne.data" border></Table> -->
        <div class="table">
          <p class="table-item">
            <span>项目</span>
            <span>要求</span>
            <span>检测值</span>
          </p>
          <p class="table-item" v-for="(it, index) in item.values" :key="index">
            <span>{{ it.name }}</span>
            <span>{{ it.remark }}</span>
            <span style="color: #06baa1">{{ it.value }}</span>
          </p>
        </div>
      </div>
      <!-- <p class="claim">沼气肥施用卫生学要求</p> -->
      <!-- <Table :columns="tableTwo.columns" :data="tableTwo.data" border></Table> -->
      <!-- <div class="table">
      <p class="table-item">
        <span>项目</span>
        <span>要求</span>
        <span>检测值</span>
      </p>
      <p class="table-item" v-for="(item, index) in dataTwo" :key="index">
        <span>{{ item.name }}</span>
        <span>{{ item.claim }}</span>
        <span style="color: #06baa1">{{ item.value }}</span>
      </p>
    </div> -->
    </div>
  </surround-box>
</template>

<script>
import surroundBox from "@/components/mapComp/surroundBox2.vue";
export default {
  components: {
    surroundBox,
  },
  name: "",
  props: {
    currentFarm: Object,
  },
  data() {
    return {
      // dataOne: [
      //   { name: "蛔虫卵", claim: "死亡率≥95%", value: "96.5%" },
      //   {
      //     name: "钩虫卵",
      //     claim: "使用粪液不应检出活的钩虫卵",
      //     value: "达标",
      //   },
      //   {
      //     name: "粪大肠杆菌群数",
      //     claim: "常温发酵≤105 个/L,高温发酵≤100 个/L",
      //     value: "52316",
      //   },
      //   {
      //     name: "蚊子、苍蝇",
      //     claim: "粪液中不应有蚊蝇幼虫，池周围不应有活的蛆、蛹或新羽化的成蝇",
      //     value: "达标",
      //   },
      // ],
      // dataTwo: [
      //   { name: "蛔虫卵沉降率", claim: "95%以上", value: "96.5%" },
      //   {
      //     name: "血吸虫卵和钩虫卵",
      //     claim: "使用粪液不应有活的钩虫卵和血吸虫卵",
      //     value: "达标",
      //   },
      //   { name: "粪大肠杆菌值", claim: "10-1 ~ 10-2", value: "0.05" },
      //   {
      //     name: "蚊子、苍蝇",
      //     claim:
      //       "有效地控制蚊蝇孽生，沼液中无孑孓，池周围不应有活的蛆、蛹或新羽化的成蝇",
      //     value: "达标",
      //   },
      //   { name: "沼气池粪渣", claim: "同固体肥施用标准", value: "" },
      // ],
      //检测报告选择下拉框
      qualitySel: [],
      //报告详情
      qualityData: [],
      //显示下拉框名字
      qualityInfo: "",
    };
  },
  methods: {
    //检测报告
    getQuality() {
      this.$post(this.$api.QUALITY_DETECTION.LIST, {
        farmId: this.currentFarm.sourceId ? this.currentFarm.sourceId : "",
      }).then((res) => {
        this.qualitySel = res.list;
        this.qualityData = res.list.length > 0 ? res.list[0].valueList : [];
        this.qualityInfo = res.list[0].name;
        // console.log(res.list);
        // console.log(this.qualityData);
      });
    },
    //下拉框
    changeSel(val) {
      this.$post(this.$api.QUALITY_DETECTION.LIST, {
        name: val,
      }).then((res) => {
        this.qualityData = res.list.length > 0 ? res.list[0].valueList : [];
      });
    },
  },
  mounted() {
    this.getQuality();
  },
  watch: {
    currentFarm(n) {
      this.getQuality();
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../../index.less");
.detect-pane {
  width: 100%;
  min-height: 500px;
  max-height: 812px;
  padding: 16px;
  // background: rgba(23, 34, 60, 0.6);
  color: #fff;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .title {
    text-align: center;
    padding-bottom: 16px;
  }
  .claim {
    padding: 10px 0;
  }
  .table {
    width: 100%;
    .table-item {
      width: 100%;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      border-left: 1px solid #fff;
      &:last-child {
        border-bottom: 1px solid #fff;
      }
      display: flex;
      align-items: stretch;
      span {
        width: 33%;
        min-height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        &:not(:last-child) {
          border-right: 1px solid #fff;
        }
      }
    }
  }
}
// /deep/ .ivu-select-selection {
//   background-color: rgba(23, 34, 60, 0.6);
// }
// /deep/ .ivu-select-dropdown {
//   background-color: rgba(23, 34, 60, 1);
// }
</style>