<template>
  <surround-box :width="350" :title="currentFarm.mapName + '施用点'">
    <div class="info-pane">
      <!-- <p class="title">{{ currentFarm.mapName }}施用点</p> -->
      <div class="basic-info">
        <p class="basic-info-item">
          <span>联系人：</span>
          <span>{{ farmInfo.chargePerson }}</span>
        </p>
        <p class="basic-info-item">
          <span>联系电话：</span>
          <span>{{ farmInfo.chargePersonTel }}</span>
        </p>
        <p class="basic-info-item">
          <span>地块地址：</span>
          <span>{{ farmInfo.address }}</span>
        </p>
        <p class="basic-info-item">
          <span>地块面积：</span>
          <span>{{ farmInfo.area }}㎡</span>
        </p>
      </div>
      <!-- <p class="buy-title">肥料购买情况</p>
    <div class="buy-info">
      <p class="buy-item" v-for="(item, index) in 3" :key="index">
        <span>xxxx肥料</span>
        <span>2000kg</span>
      </p>
    </div> -->
      <p class="buy-title">粪污购买情况</p>
      <div class="buy-info">
        <p class="buy-item" v-for="(item, index) in buyWasteList" :key="index">
          <span>{{ item.product_name }}</span>
          <span>{{ item.product_num + item.product_unit }}</span>
        </p>
      </div>
    </div>
  </surround-box>
</template>

<script>
import surroundBox from "@/components/mapComp/surroundBox2.vue";
export default {
  components: {
    surroundBox,
  },
  name: "",
  props: {
    currentFarm: Object,
  },
  data() {
    return {
      farmInfo: {},
      processId: "",
      buyWasteList: [],
    };
  },
  methods: {
    getFarmView() {
      return this.$post(this.$api.BASE_MANAGE.VIEW, {
        id: this.currentFarm.sourceId,
      }).then((res) => {
        this.farmInfo = res;
        this.processId = res.creator;
      });
    },
    //粪污购买情况
    getBuyWaste() {
      this.$post(this.$api.BASE_MANAGE.BUY_WASTE, {
        // farmId: this.currentFarm.sourceId,
        processId: this.processId,
      }).then((res) => {
        this.buyWasteList = res;
      });
    },
  },
  mounted() {
    this.getFarmView().then(() => {
      this.getBuyWaste();
    });
  },
  watch: {
    currentFarm(n) {
      this.getFarmView().then(() => {
        this.getBuyWaste();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.info-pane {
  width: 100%;
  height: 100%;
  padding: 16px;
  // background: rgba(23, 34, 60, 0.6);
  color: #fff;
  .title {
    text-align: center;
  }
  .basic-info {
    .basic-info-item {
      padding-bottom: 10px;
    }
  }
  .buy-info {
    width: 100%;
    padding: 10px 0;
    .buy-item {
      width: 100%;
      border-top: 1px solid #fff;
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;
      &:last-child {
        border-bottom: 1px solid #fff;
      }
      span {
        display: inline-block;
        width: 50%;
        height: 35px;
        line-height: 35px;
        text-align: center;
        &:first-child {
          border-right: 1px solid #fff;
        }
      }
    }
  }
}
</style>