<template>
  <div class="apply-map">
    <apply-map
      :farms="farms"
      :currentFarm="currentFarm"
      @farm-click="farmClick"
      @site-click="siteClick"
      @zoom-change="zoomChange"
    ></apply-map>

    <div class="sel-box">
      <Select
        v-model="farmId"
        style="width: 350px"
        placeholder="选择社会化服务组织"
      >
        <Option
          v-for="item in farms"
          :value="item.sourceId"
          :key="item.sourceId"
          >{{ item.mapName }}</Option
        >
      </Select>
    </div>
    <div class="farm-legend">
      <farm-legend :currentFarm="currentFarm"></farm-legend>
    </div>
    <div class="apply-info" v-if="currentFarm">
      <apply-info :currentFarm="currentFarm"></apply-info>
    </div>
    <div class="detect-info" v-if="currentFarm">
      <detect-info :currentFarm="currentFarm"></detect-info>
    </div>
  </div>
</template>

<script>
import applyMap from "./map";
import farmLegend from "./component/farmLegend"; //图例
import applyInfo from "./component/applyInfo" //施用点信息
import detectInfo from "./component/detectInfo"
export default {
  name: "",
  components: {
    applyMap,
    farmLegend,
    applyInfo,
    detectInfo
  },
  data() {
    return {
      farms: [],
      currentFarm: null,
      farmId: ''
    };
  },
  watch: {
    farmId(n) {
      this.currentFarm = this.farms.filter(
        (item) => item.sourceId == n
      )[0];
    },
  },
  methods: {
    getFarms() {
      this.$post(this.$api.MAP.MAP_CFG_INFO, {
        farmType: "4",
      }).then((res) => {
        this.farms = res.list;
      });
    },
    farmClick(farm) {
      this.currentFarm = farm;
      this.farmId = farm.sourceId;
    },
    siteClick(site) {},
    zoomChange(zoom) {
      if (zoom < 13) {
        this.currentFarm = null;
        this.farmId = "";
      }
    },
  },
  mounted() {
    this.getFarms();
  },
};
</script>

<style lang="less" scoped>
@import url('./index.less');
</style>